/* Colors Neuhaus */
/* Fonts Neuhaus */
.image-content-tile-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 768.98px) {
    .image-content-tile-container {
      padding: 0; } }
  @media (min-width: 769px) {
    .image-content-tile-container {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; } }
  .image-content-tile-container .content-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%; }
    @media (min-width: 769px) {
      .image-content-tile-container .content-column {
        max-width: 50%; } }
    .image-content-tile-container .content-column .experience-custom_layouts-imageContentSlots {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex; }
  .image-content-tile-container .image-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    padding-left: 0;
    padding-right: 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    .image-content-tile-container .image-column .photo-tile-figure {
      margin: 0; }
    @media (min-width: 769px) {
      .image-content-tile-container .image-column {
        max-width: 50%; } }

.photo-tile-figure {
  height: 100%; }
  .photo-tile-figure .photo-tile-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }

.content-custom-container {
  padding: 0 40px; }
  @media (min-width: 1680px) {
    .content-custom-container {
      max-width: 840px; } }
